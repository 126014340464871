.root {

}

.item {
    svg {
        fill: #F3A400;
    }

    &:global.is-inactive {
        opacity: 0.25;
        svg {
            fill: currentColor;
        }
    }
}
