//@import "styles/core/fonts";
@import "styles/core/normalize";
@import "styles/core/defaults";
@import "styles/core/easing";

body {

    font-size: 16px;

    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    overflow: hidden;

    font-family: Verdana, sans-serif;
    font-weight: normal;

    user-select: none;

    background-color: darken(#0D2C6D, 5%);
    color: #FEFFFD;

    scroll-behavior: smooth;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

strong, b {
    font-weight: bold;
}

em, i {
    font-style: italic;
}

img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

svg {
    display: block;
}

.screen {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.wysiwyg {

    font-size: 15px;
    line-height: 1.6;

    *:last-child {
        margin-bottom: 0;
    }

    p, ul, ol {
        margin-bottom: (22/16) * 1em;
    }

    h3 {
        color: #CDCDCD;
        font-size: 30px;
        line-height: 1.2;
        margin-bottom: 5px;
    }
}

.clickable {
    transition: var(--transition, transform 0.1s);
    transform-origin: var(--transform-origin, center center);
    &:active,
    a:active &,
    button:active & {
        transform: var(--transform, scale(0.9, 0.9));
        transition: none;
    }
}

.u-gpu {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
}

.u-scrollable {
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
}



/**
    Some people think this belongs in /vendor. I'm not one of those people
 */

/*! Flickity v2.1.1
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
    position: relative;
}

.flickity-enabled:focus {
    outline: none;
}

.flickity-viewport {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

