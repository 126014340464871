.item {

    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 2px 1px 2px 0 rgba(6,22,56,0.50);

    > * {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        color: #000;
    }

    .strapline {
        color: #7E7E7E;
        text-transform: uppercase;
    }

    .heading {
        color: #0D2C6D;
        text-transform: uppercase;
        font-weight: bold;
    }

    .image {

    }

    .text {

    }
}
