.root {
    display: flex;
    width: 100%;
    flex: 0 0 auto;
    background-color: #F7F6F7;
    border-radius: 10px;
    overflow: hidden;
    min-height: 87px;
    box-shadow: 2px 1px 2px 0 rgba(6,22,56,0.50);
}
