@import "../styles/core/easing";

.timeline {
    display: flex;
    width: 100%;
    height: 140px;
    flex: 0 0 auto;
    position: relative;
    z-index: 1;
}

.items {
    flex: 0 0 auto;
    min-width: 100%;
    height: 140px;
    position: relative;
}

.item {
    position: absolute;
    top: 0;
    &::before {
        content: "";
        display: block;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        position: absolute;
        left: 5px;
        top: 5px;
        background-color: #F0AB00;
        border-radius: 20px;
        z-index: 1;
        transition: background-color 0.3s $easeOutCubic;
    }
    &::after {
        content: "";
        display: block;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 20px;
        border: 10px solid rgba(#0ABBEF, 0.5);
        z-index: 2;
    }
    &:global.is-active::before {
        background-color: #fff;
    }
}

.itempanels {
    & :global .flickity-enabled,
    & :global .flickity-viewport {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100% !important;
    }
}
