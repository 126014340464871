@import "../styles/core/easing";

@keyframes pulse {
    from { transform: scale(1); }
    70% { transform: scale(1); }
    75% { transform: scale(1.2); }
    90% { transform: scale(1); }
    to { transform: scale(1); }
}

.areabuttonwrapper {
    position: absolute;
    top: 50%;
    animation-name: pulse;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    &:global.paused {
        animation-name: unset;
    }
}

.areabutton {

    display: block;
    position: absolute;
    top: 50%;

    &:global.is-active > div {
        &::before {
            background-color: #fff;
        }
    }

    > div {

        width: calc(100% - 20px);
        height: calc(100% - 20px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        font-size: 12px;
        position: absolute;
        top: 10px;
        left: 10px;
        color: #0D2C6D;
        text-align: center;

        > * {
            width: 100%;
            position: relative;
            z-index: 2;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            left: -5px;
            top: -5px;
            background-color: #F0AB00;
            border-radius: 100%;
            z-index: 1;
            transition: background-color 0.3s $easeOutCubic;
        }
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: -10px;
            top: -10px;
            border-radius: 100%;
            border: 10px solid rgba(#0ABBEF, 0.5);
            z-index: 2;
        }
    }
}
