.root {

}

.slides {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
}

.slide {

    width: 100vw;
    height: 100vh;
    overflow: hidden;
    padding: 80px 20vw 80px;

    > div {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
    }

    figure {

        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 100%;
        height: 100%;
        margin: auto;
        flex: 0 0 auto;

        img {
            width: 100%;
            height: auto;
        }

        div {
            width: 100%;
            flex: 0 0 auto;
        }

        > * {
            width: 100%;
            flex: 0 0;
        }
    }

    figcaption {
        //margin: 15px 0 0;
        padding: 15px 0 0;
        line-height: 1.5;
        font-size: 15px;
        p + p {
            margin-top: 5px;
        }
    }
}

.prevnextbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20vw;
    height: 100vh;
    top: 0;
    pointer-events: none;
    button {
        display: block;
        width: 80px;
        height: 80px;
        pointer-events: auto;
    }
}

:global {

}
