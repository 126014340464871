.toggle {
    position: fixed;
    top: 40px;
    right: 50px;
    width: 80px;
    height: 80px;
    z-index: 101;
}

.root {
    position: fixed;
    top: 0; left: 0;
}

.menu {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(180deg, #0F2D6D 0%, #0ABBEF 100%);
    position: relative;
}

.items {
    width: 100%;
    padding: 80px;
    margin: auto;
    max-width: 780px;
}

.item {
    width: 100%;
    display: flex;
    position: relative;
    > * {
        display: flex;
        flex-direction: column;
    }
    button, a {
        display: flex;
        flex-direction: column;
        font-size: 30px;
        line-height: 1.2;
        text-transform: uppercase;
        text-shadow: 1px 1px 0 rgba(15,45,109,0.50);
        padding: 20px 0;
        position: relative;
        span {
            display: inline-block;
            padding-left: 38px;
        }
        svg {
            display: block;
            flex: 0 0 auto;
            width: 12px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -9px;
            transition: transform 0.3s;
        }
    }
    ul {
        display: flex;
        max-width: 100%;
        flex-direction: column;
        padding: 0 0 20px;
    }
    &:global.is-active,
    &:global.is-expanded {
        button > svg,
        > a > svg {
            g[fill="#F0AB00"] {
                fill: currentColor;
            }
            g[stroke="#F0AB00"] {
                stroke: currentColor;
            }
        }
    }
    &::before {
        content: "";
        display: block;
        width: 200vw;
        height: calc(100% - 10px);
        position: absolute;
        top: 0; left: -100vw;
        background: #F1F1F1;
        border: 1px solid #D4D4D4;
        mix-blend-mode: multiply;
        opacity: 0;
        transition: opacity 0.3s;
    }
    &:global.is-expanded {
        &::before {
            opacity: 1;
        }
        button > svg {
            transform: rotate(90deg);
        }
    }
}

.childitem {
    padding-left: 38px;
    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 24px;
        line-height: 1.2;
        span {
            padding-left: 0;
        }
        svg {
            width: 12px;
            height: 12px;
            position: static;
            margin: 0 18px 0 0;
            left: auto;
            top: auto;
        }
    }
    &:global.is-active {
        svg {
            g {
                stroke: currentColor;
            }
        }
    }
}
